import * as React from "react";
import {useMediaQuery} from '@material-ui/core';
import {
    Datagrid,
    List,
    TabbedShowLayout,
    Tab,
    SimpleList,
    TextField,
    ArrayField,
    Show,
    SimpleShowLayout,
    DateField,
    SelectField,
    ReferenceField,
    EditButton,
    Create, SimpleForm, TextInput, Edit, SelectInput
} from 'react-admin';
import ReactEcharts from "echarts-for-react";
import {TagFilter} from "./tags";


export const ConfigList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} sort={{
            field: 'updateAt', order: 'DESC' }} title="配置列表" filters={<TagFilter/>} exporter={false}>
            {isSmall? (
                <SimpleList
                    primaryText={ record => `${record.key}:${record.value}` }
                    secondaryText={ record => record.description }
                    tertiaryText={ record => record.updateAt }/>
            ): (
                <Datagrid>
                    <TextField label="ID" source="id" />
                    <TextField label="名称" source="key" />
                    <TextField label="值" source="value" />
                    <TextField label="描述" source="description" />
                    <DateField showTime={true} label="创建时间" source="createAt" />
                    <DateField showTime={true} label="更新时间" source="updateAt" />
                    <EditButton />
                </Datagrid>)}
        </List>
    );
}

export const ConfigCreate = (props) => {
    return (<Create {...props} title="配置创建">
        <SimpleForm>
            <TextInput source="key" label="名称" />
            <TextInput source="value" label="值" />
            <TextInput source="description" label="描述" multiline={true} />
        </SimpleForm>
    </Create>);
}

export const ConfigEdit = (props) => {
    return (<Edit title="配置修改" {...props}>
        <SimpleForm>
            <TextInput source="key" label="名称" />
            <TextInput source="value" label="值" />
            <TextInput source="description" label="描述" multiline={true} />
        </SimpleForm>
    </Edit>);
}
