//判断是否拥有权限
export const checkPermission = (permission, permissionPath, permissions) => {
    const permissionStr = permission + '|' + permissionPath
    if(!permissions){
        return false
    }
    if(permissions === 'admin'){
        return true;
    }
    return permissions.indexOf(permissionStr) !== -1;
}
