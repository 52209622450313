import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List, Datagrid, TextInput, TextField, SimpleForm, EditButton,
    Edit, Create, SimpleList, SelectInput } from 'react-admin';

export const PermissionList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}
              sort={{
                  field: 'id', order: 'DESC' }}
              title="权限列表"
              exporter={false}
        >
            {isSmall? (
                <SimpleList
                    primaryText={record => record.permission  + '@' + record.permissionPath}
                    secondaryText={record => record.remark}
                    tertiaryText={record => new Date(record.updateTime).toLocaleDateString()}
                />
            ): (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="remark" label="备注" />
                    <TextField source="permission" label="权限" />
                    <TextField source="permissionPath" label="权限路径" />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const PermissionCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="permission" choices={[
                { id: 'list', name: '查看' },
                { id: 'create', name: '创建' },
                { id: 'edit', name: '编辑' },
                { id: 'delete', name: '删除' }
            ]} />
            <TextInput source="permissionPath" />
            <TextInput multiline source="remark" />
        </SimpleForm>
    </Create>
);

export const PermissionEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput source="permission" choices={[
                { id: 'list', name: '查看' },
                { id: 'create', name: '创建' },
                { id: 'edit', name: '编辑' },
                { id: 'delete', name: '删除' }
            ]} />
            <TextInput source="permissionPath"/>
            <TextInput multiline source="remark" />
        </SimpleForm>
    </Edit>
);
