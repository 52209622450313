import * as React from "react";
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { Link } from "react-admin";
import {
    List,
    Datagrid,
    TextInput,
    TextField,
    ReferenceField,
    Show,
    DateField,
    SelectInput,
    ReferenceInput,
    EditButton,
    FunctionField,
    SearchInput,
    TabbedForm,
    FormTab,
    Edit,
    Create,
    Filter,
    SimpleList,
    SimpleFormIterator,
    ArrayInput,
    NumberInput,
    TabbedShowLayout,
    Tab,
    ImageInput,
    ImageField,
    useListContext,
    useNotify,
    fetchStart,
    fetchEnd,
    TopToolbar,
    sanitizeListRestProps,
    CreateButton
} from 'react-admin';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label} from "recharts";
import {checkPermission} from "./auth";
import ReactEcharts from 'echarts-for-react';
import {StaffCardList} from "./staffs";
import {cloneElement, useState} from "react";
import ListActions from './ListActions';

const useTabStyles = makeStyles(theme => ({
    tabContent: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        minHeight: '800px'
    },
    chart: {
        width: '60%',
    },
    userList: {
        width: '40%',
        height: '600px',
        textOverflow: 'hidden'
    }
}));

const DivisionTags = ({record}) => {
    if(record && record.divisionList){
        return (
            <ul>
                {record.divisionList.map(elem => (
                    <li key={elem.divisionId}>维度名称:{elem.divisionName}-分数:{elem.score}-权重:{elem.weight}</li>
                ))}
            </ul>
        );
    }
    return null;
}

const MyEditButton = props => {
    if(props.record && props.record.model_type && props.record['model_type'] !== 1){
        return (
            <span>-</span>
        );
    }
    return (
        <EditButton {...props} />
    );
}

MyEditButton.defaultProps = EditButton.defaultProps


export const CompetenceModelList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={<CompetenceModelFilter/>} sort={{
            field: 'id', order: 'DESC' }} title="胜任力模型列表" actions={<ListActions resource="competencemodels"/>}>
            {isSmall? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.description}
                />
            ): (
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    {checkPermission('list', 'users', permissions)? (
                        <ReferenceField label="创建者" source="admin_id" reference="users" link={false}>
                            <TextField source="fullName" />
                        </ReferenceField>
                    ): null}
                    <TextField source="company_name" label="创建公司" />
                    <TextField source="name" label="名称" />
                    <TextField source="description" label="描述" />
                    <FunctionField label="类型" render={ record => record.model_type === 1? '通用': (record.model_type === 2? '企业': '测试') } />
                    <FunctionField label="价格" render={ record => record.price === 0.00? '免费': record.price } />
                    <DivisionTags label="维度信息" />
                    <MyEditButton label="操作" />
                </Datagrid>
            )}
        </List>
    );
}

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = '名称为必填选项';
    }
    if (!values.description) {
        // You can return translation keys
        errors.description = '描述为必填选项';
    }
    if(!values.price){
        errors.price = "价格为必填选项";
    }
    if(!values.image){
        errors.image = "图片必须上传";
    }
    if(!values.divisionList){
        errors.image = "必须选择纬度分数"
    }
    return errors
};

export const CompetenceModelCreate = (props) => (
    <Create {...props } title="创建通用模型">
        <TabbedForm validate={validateUserCreation}>
            <FormTab label="模型信息">
                <TextInput source="name"/>
                <TextInput multiline source="description"/>
                <SelectInput label="Model Type" source="model_type" choices={[
                    { id: '1', name: '通用模型'},
                    { id: '2', name: '企业模型', disabled: true},
                    { id: '3', name: '测试模型'}
                ]} optionValue="id" />
                <NumberInput source="price" label="价格"/>
                <ImageInput source="image" label="模型配图" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="配置维度">
                <ArrayInput source="divisionList">
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="维度"
                            source="id"
                            reference="divisions"
                            perPage={100}
                            allowNull
                            allowEmpty
                            format={(v) => (!v ? null : v)}>
                            <SelectInput optionText={(record) => `${record.name}[${record.type}]`} optionValue="id" />
                        </ReferenceInput>
                        <NumberInput label="分数" source="score" />
                        <NumberInput label="权重" source="weight" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
)

export const CompetenceModelEdit = (props) => (
    <Edit {...props} title="修改通用模型">
        <TabbedForm>
            <FormTab label="模型信息">
                <TextInput source="name"/>
                <TextInput multiline source="description"/>
                <SelectInput label="Model Type" source="model_type" choices={[
                    { id: '1', name: '通用模型'},
                    { id: '2', name: '企业模型', disabled: true},
                    { id: '3', name: '测试模型'}
                ]} optionValue="id" />
                <NumberInput source="price" label="价格"/>
                <ImageInput source="image" label="模型配图" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="配置维度">
                <ArrayInput source="divisionList">
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="维度"
                            source="id"
                            reference="divisions"
                            perPage={100}
                            allowNull
                            allowEmpty
                            format={(v) => (!v ? null : v)}>
                            <SelectInput optionText={(record) => `${record.name}[${record.type}]`} optionValue="id" />
                        </ReferenceInput>
                        <NumberInput label="分数" source="score" />
                        <NumberInput label="权重" source="weight" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
)

export const CompetenceModelFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="model_type" label="模型类型" choices={[
            { id: 1, name: '通用模型' },
            { id: 2, name: '企业模型' },
            { id: 3, name: '测试模型' }
        ]}/>
    </Filter>
)

const CustomTooltip = ({active, payload, label}) => {
    if (active) {
        console.log(payload);
        const final = payload.map((entry, i) => {
            let {name, value} = entry;
            return (
                // eslint-disable-next-line react/no-array-index-key
                <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={{
                    display:'inline-flex', width: '100px', height: '25px', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <span className="recharts-tooltip-item-name">{name}</span>
                    <span className="recharts-tooltip-item-separator">:&nbsp;</span>
                    <span className="recharts-tooltip-item-value">{Math.round(value*100)/100}</span>
                    <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
                </li>
            );
        });
        return (
            <div style={{margin: 0,
                padding: 10,
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                whiteSpace: 'nowrap'}}>
                <p>
                    { `${label}`}
                </p>
                <ul className="recharts-tooltip-item-list" style={{
                    padding: 0, margin: 0, display:'flex', flexFlow: 'row wrap', maxWidth: '600px', minHeight: '400px' }}>
                    {final}
                </ul>
            </div>

        );
    }
    return null;
}


const StaffSampleChart = ({record, source, label}) => {
    let data = JSON.parse(record[source]);
    if(!data){
        return null;
    }
    const newData = [];
    for(const each in data){
        newData.push(Object.assign(data[each], {name: each}));
    }
    const getRandomInt = (min, max) => {
        let minVal = Math.ceil(min);
        let maxVal = Math.floor(max);
        return Math.floor(Math.random() * (maxVal - minVal) + minVal)
    }
    const getRandomColor = () => {
        const mkColor = () => getRandomInt(0,155).toString(16).toUpperCase()
        return '#' + mkColor() + mkColor() + mkColor()
    }
    const Lines = Object.keys(newData[0]).map((elem, index) => {
        if(elem.indexOf('员工') !== -1){
            return (<Line type="monotone" key={index} dataKey={elem} stroke={ getRandomColor() } />);
        }
        return null;
    });
    return (
    <LineChart
        layout="vertical"
        width={1000}
        height={4800}
        data={newData}
        margin={{
            top: 25, right: 20, left: 180, bottom: 25,
        }}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis  type="number" >
            <Label value={label} offset={0} position="insideBottom" />
        </XAxis>
        <YAxis dataKey="name" type="category" />
        { Lines.length > 20? (
            <Tooltip
                content={<CustomTooltip/>}
            />
            ): (<Tooltip />) }
        <Legend />
        {Lines}
        <Line type="monotone" dataKey="std" stroke="#82ca9d" />
    </LineChart>
    );
}

const ModelRadarChartEcharts = ({record, source, style, series}) => {
    const data = JSON.parse(record[source]);
    const indicator = data.map(item => {
        const color = item.id <= 23? 'red': (item.id > 39? 'green': 'blue')
        return {'name': item.subject, 'max': item.fullMark, color};
    });
    const values = data.map(item => item.score);
    let s = [
        {
            value: values,
            name: '模型'
        }
    ]
    if(series && series.length > 0){
        s = s.concat(series);
    }
    // console.log(s);
    const option = {
        title: {},
        tooltip: {},
        legend: {
        },
        radar: {
            // shape: 'circle',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    padding: [3, 5]
                }
            },
            indicator,
        },
        series: [{
            // name: '用户分数',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: s
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />)
}

export const CompetenceModelShow = ({permissions, ...props}) => {
    const classes = useTabStyles();
    const [staffs, setStaffs] = useState([])
    const [series, setSeries] = useState([])
    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label="概览">
                <TextField source="name" label="模型名称"/>
                <FunctionField label="类型"
                               render={record => record.model_type === 1 ? '通用' : (record.model_type === 2 ? '企业' : '测试')}/>
                <FunctionField label="价格" render={record => record.price === 0.00 ? '免费' : record.price}/>
                <TextField source="description" label="模型描述"/>
                {checkPermission('list', 'users', permissions) ? (
                    <ReferenceField label="创建者" source="admin_id" reference="users" link={false}>
                        <TextField source="fullName"/>
                    </ReferenceField>
                ) : null}
                <TextField source="company_name" label="公司名称"/>
                <DateField label="发布时间" source="createTime"/>
            </Tab>
            <Tab label="建模员工数据">
                <StaffSampleChart source="staff_sample" label="建模员工数据"/>
            </Tab>
            <Tab label="模型数据">
                <ModelRadarChartEcharts source="raw_content" label="模型数据" style={{
                    height: '600px', width: '100%'}}/>
            </Tab>
            <Tab label="投射测试" contentClassName={ classes.tabContent }>
                <ModelRadarChartEcharts
                    style={{
                        height: '500px',width: '100%'}}
                    className={ classes.chart }
                    source="raw_content"
                    label="模型数据"
                    series={series}/>
                {
                    checkPermission('list', 'staffs',permissions)? (<StaffCardList
                        style={{width: '40%'}}
                        className={ classes.userList }
                        source="raw_content"
                        staffs={staffs}
                        setStaffs={setStaffs} setSeries={setSeries} />): null
                }

            </Tab>
            <Link to={{pathname:'/competencemodels'}}><button style={{border:'none',position:'relative',top:12,color:'#666',backgroundColor:'#fff',cursor:'pointer'}}>返回</button></Link>
            {/* <div onClick={()=>{console.log(123456);}}>返回</div> */}
            
        </TabbedShowLayout>
    </Show>);
};
