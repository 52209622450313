import {fetchUtils} from "react-admin";
import {stringify} from 'query-string';
import {getToken, apiUrl} from './dataProvider';
// const apiUrl = 'https://test-poseidon.9gongli.com/api';
const httpClient = fetchUtils.fetchJson;

export default {
    // called when the user attempts to log in
    login: ({username, password}) => {
        return httpClient(
            `${apiUrl}/token`,
            {
                method: 'POST',
                headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
                body: stringify({username, password})
            }
        ).then(({headers, json}) => {
            localStorage.setItem('admin_token', json.access_token)
            localStorage.setItem('username', username);
            return httpClient(`${apiUrl}/users/me`, {
                method: 'GET',
                headers: new Headers({
                    Accept: 'application/json', Authorization: `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                })
            }).then(({headers, json}) => {
                localStorage.setItem('me', JSON.stringify(json));
                const permissions = json.permissions
                const roles = json.roles
                if (roles.includes(1)) {
                    localStorage.setItem('admin', '1');
                }
                let permissionStringArr = []
                permissions.forEach(permission => {
                    permissionStringArr.push(permission.permission + '|' + permission.permissionPath)
                })
                localStorage.setItem('permissions', permissionStringArr.join('^'))
            })
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.clear()
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('admin_token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const admin = localStorage.getItem('admin')
        if (admin) {
            return Promise.resolve('admin');
        }
        const permissions = localStorage.getItem('permissions');
        if (permissions) {
            return Promise.resolve(permissions);
        }
        return Promise.resolve();
    },
};
