import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List, Datagrid, TextInput, TextField, EditButton,
    Edit, Create, SimpleList, TabbedForm, FormTab, ReferenceArrayInput, SelectArrayInput } from 'react-admin';

export const RoleList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}
              sort={{
                  field: 'id', order: 'DESC' }}
              title="角色列表"
              exporter={false}
        >
            {isSmall? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.desc}
                    tertiaryText={record => new Date(record.updateTime).toLocaleDateString()}
                />
            ): (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" label="名称" />
                    <TextField source="desc" label="描述" />
                    <TextField source="level" label="等级" />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const RoleCreate = (props) => {
    return (
        <Create {...props} title="角色创建">
            <TabbedForm>
                <FormTab label="角色信息">
                    <TextInput source='name' />
                    <TextInput source='desc' />
                    <TextInput source='level' />
                </FormTab>
                <FormTab label="分配权限">
                    <ReferenceArrayInput source="permissions" reference="permissions">
                        <SelectArrayInput optionText="remark" />
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export const RoleEdit = ({ permissions, ...props }) => {
    return (
        <Edit {...props} title="角色修改">
            <TabbedForm>
                <FormTab label="角色信息">
                    <TextInput source='name' />
                    <TextInput source='desc' />
                    <TextInput source='level' />
                </FormTab>
                <FormTab label="分配权限">
                    <ReferenceArrayInput source="permissions" reference="permissions">
                        <SelectArrayInput optionText="remark" />
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
