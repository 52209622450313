import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';

export const apiUrl = 'https://poseidon.9gongli.com/api';
// export const apiUrl = 'http://localhost:3000/api';
export const getToken = () => {
    return localStorage.getItem('admin_token')
};
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json', Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json'
        });
    }
    // add your own headers here
    options.headers.set('X-Custom-Header', 'foobar');
    return fetchUtils.fetchJson(url, options);
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);

    });

export default {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json.data,
            total: parseInt(json.total, 10),
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json.data,
            total: parseInt(json.total, 10),
        }));
    },

    update: (resource, params) => {
        const updateAction = (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data)
            }).then(({json}) => ({data: json}));

        // console.log(params.data.image);

        if (!params.data.images && !params.data.image) {
            return updateAction(resource, params)
        }

        const newPictures = params.data.images ? params.data.images.filter(
            p => p.rawFile instanceof File
        ) : params.data.image.rawFile instanceof File ? [params.data.image] : null

        if(!newPictures){
            return updateAction(resource, params)
        }

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewPictures => {
                    const data = params.data.images ? {
                        ...params.data,
                        images: [
                            ...transformedNewPictures,
                        ],
                    } : {
                        ...params.data,
                        image: {
                            ...transformedNewPictures[0]
                        }
                    }
                    // console.log('fuck', data);
                    return updateAction(resource, {
                        ...params,
                        data
                    })
                }
            );
    },


    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}/?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({data: json}));
    },

    create: (resource, params) => {
        const createAction = (resource, params) => httpClient(`${apiUrl}/${resource}/`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({
            data: {...params.data, id: json.id},
        }))

        console.log(params.data.images);

        if (!params.data.images && !params.data.image) {
            return createAction(resource, params)
        }

        const newPictures = params.data.images ? params.data.images.filter(
            p => p.rawFile instanceof File
        ) : params.data.image.rawFile instanceof File ? [params.data.image] : null

        if(!newPictures){
            return createAction(resource, params)
        }

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewPictures => {
                    const data = params.data.images ? {
                        ...params.data,
                        images: [
                            ...transformedNewPictures,
                        ],
                    } : {
                        ...params.data,
                        image: {
                            ...transformedNewPictures[0],
                        }
                    }
                    return createAction(resource, {
                        ...params,
                        data
                    })
                }
            );

    },


    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE'
        }).then(({json}) => ({data: json})),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}/?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({data: json}));
    }
};
