import * as React from "react";
import Button from '@material-ui/core/Button';
import { useCreate, useNotify, useListContext, CheckboxGroupInput} from 'react-admin';
import { Form, useForm } from 'react-final-form';

let sum = [];

const FormBody = ({ handleSubmit, type }) => {
    // enable the warn when unsaved changes feature
    const choices = [
        { id: 1, name: "16pf", valid: (type & 1) === 1 },
        { id: 2, name: "天赋", valid: (type & 2) === 2 },
        { id: 4, name: "价值观", valid: (type & 4) === 4 }
        ];
    const form = useForm();
    const {selectedIds} = useListContext();
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const notify = useNotify();
    const total = sum.reduce(reducer, 0);
    const params = {ids: selectedIds, type: total };
    let [createModel] = useCreate('staffmodels', params,  {
        onSuccess: () => {
            notify('新胜任力模型创建成功！')
        },
        onFailure: error => notify('新胜利力模型创建失败！')
    });
    return (
        <form onSubmit={handleSubmit} style={{
            display: 'flex',
            flexFlow: 'row',
            width: '100%',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center' }}>
            <CheckboxGroupInput
                label="选择建模量表"
                style={{ alignSelf: 'center' }}
                source="mask"
                choices={choices.filter(item => item.valid)}
                onChange={(val)=> {
                    const choicesIds = choices.filter(item => item.valid).map(item => item.id);
                    form.change('mask', val.filter(item => choicesIds.includes(item)));
                    sum = val;
                    console.log('sum', sum);
                }}
            />
            <Button
                color="primary"
                variant="contained"
                disabled={ selectedIds.length < 5 || sum.length === 0 }
                style={{
                    alignSelf: 'center', width: '150px' }}
                onClick={createModel}>
                创建模型
            </Button>
        </form>
    );
};


export const StaffModelCreate =  () => {
    const {data, selectedIds} = useListContext();
    const type = selectedIds.map(id => data[id]).reduce((sum, staff) => sum & staff.test_status, 7);

    return (<Form onSubmit={() => {}} component={FormBody} type={type} />);
};
