import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const CustomImageField = ({source, record = {}, width, isSVG = record['isSVG'] }) => {
    const [open, setOpen] = React.useState(false);
    const src = record[source]
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const image = (width) => isSVG? (<img
        alt=''
        style={{ width }}
        src={"data:image/svg+xml;base64," + src }
        onClick={handleClickOpen}
    />): (<img
        alt=''
        style={{ width }}
        src={src}
        onClick={handleClickOpen}
    />);
    const dialog = (<Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
    >
        <DialogTitle>图片预览</DialogTitle>
        <DialogContent>
            {image('400px')}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                关闭
            </Button>
        </DialogActions>
    </Dialog>)
    if (isSVG) {
        return (<div>
            {image(width)}
            {dialog}
        </div>)
    }
    return (<div>
        {image(width)}
        {dialog}
    </div>)
}

CustomImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    width: PropTypes.string,
    source: PropTypes.string.isRequired,
};
