import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List, Datagrid, TextField, SimpleList } from 'react-admin';

export const JdCateList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            sort={{
                field: 'id', order: 'DESC' }}
            title="职位列表"
            exporter={false}
        >
            {isSmall? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.pid}
                />
            ): (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" label="名称" />
                    <TextField source="pid" label="父ID" />
                </Datagrid>
            )}
        </List>
    );
}
