import * as React from "react";
import {
    useListContext,
    useNotify,
    fetchStart,
    fetchEnd,
    TopToolbar,
    sanitizeListRestProps,
    CreateButton
} from 'react-admin';
import {cloneElement, useState} from "react";
import {apiUrl, getToken} from "./dataProvider";
import Button from "@material-ui/core/Button";
import { useDispatch } from 'react-redux';

export const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const dispatch = useDispatch();

    const notify = useNotify();

    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        dispatch(fetchStart());
        fetch(`${apiUrl}/${resource}/export`, { method: 'POST', headers: new Headers({
                Authorization: `Bearer ${getToken()}`
            })})
            .then((response) => {
                setLoading(false);
                dispatch(fetchEnd());
                response.blob().then(blob => {
                    const fakeLink = document.createElement('a');
                    fakeLink.style.display = 'none';
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        // Manage IE11+ & Edge
                        window.navigator.msSaveOrOpenBlob(blob, `${resource}.xlsx`);
                    } else {
                        fakeLink.setAttribute('href', URL.createObjectURL(blob));
                        fakeLink.setAttribute('download', `${resource}.xlsx`);
                        fakeLink.click();
                    }
                });
            })
            .catch((e) => {
                console.log(e);
                notify('发生错误，无法导出！');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {hasCreate? <CreateButton basePath={basePath} />: null}
            <Button color="primary" size="small" disabled={loading}
                    onClick={handleClick}
            >导出
            </Button>
        </TopToolbar>
    );
};

export default ListActions;
