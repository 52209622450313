import * as React from "react";
import {useMediaQuery, Button} from '@material-ui/core';
import {
    Datagrid,  List, TabbedShowLayout, Tab,
    SimpleList, TextField, ArrayField, Show, DateField, SelectField
} from 'react-admin';


export const StrategyList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} title="战略主题列表" exporter={false}>
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.id }
                    tertiaryText={ record => record.updateAt }/>
            ): (
                <Datagrid rowClick="show">
                    <TextField source="id" label="Id" />
                    <TextField source="name" label="名称" />
                    <DateField showTime={true} source="creatAt" label="创建时间" />
                    <DateField showTime={true} source="updateAt" label="更新时间" />
                </Datagrid>
            )}
        </List>
    );
}

export const StrategyShow = ({permissions, ...props}) => {
    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label="基本信息">
                <TextField source="id" label="Id" />
                <TextField source="name" label="名称" />
                <TextField source="creatAt" label="创建时间" />
                <TextField source="updateAt" label="更新时间" />
            </Tab>
            <Tab label="包含维度与特质列表">
                <ArrayField label='维度与特质列表' source='rules'>
                    <Datagrid>
                        <SelectField label="类型" source='modelType' choices={[
                            { id: 'division', name: '维度' },
                            { id: 'character', name: '特质' }
                        ]}/>
                        <TextField label="名称" source='name'/>
                        <TextField label="最小分数" source="minScore" />
                        <TextField label="最大分数" source="maxScore" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>)
}
