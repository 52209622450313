export const AutoMergeData = (data, deep=1) => {
    const keys = Object.keys(data).map(item => parseFloat(item));
    let minKey = deep;
    let maxKey = Math.ceil(Math.max(...keys));
    let result = {}
    for(let i=minKey; i <= maxKey; i+=deep){
        result[i] = 0;
    }
    const newKeys = Object.keys(result).map(k => parseFloat(k)).sort((a, b) => { return a-b });
    console.log(data)
    Object.keys(data).forEach(item => {
        const k = parseFloat(item);
        newKeys.forEach(key => {
            if(k > key - deep && k <= key){
                result[key.toString()] += parseInt(data[item]);
            }
        })
    });
    if(newKeys[0] === 0){
        result[deep] += data['0'];
    }
    const sortedResult = []
    newKeys.forEach((key) => {
        sortedResult.push(result[key.toString()]);
    });
    return {keys: newKeys, values: sortedResult };
}
