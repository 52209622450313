import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List, Datagrid, TextField, SimpleList, Show, TabbedShowLayout, Tab, FunctionField, ArrayField
} from 'react-admin';

// const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
//     <TableRow key={id}>
//         {/* first column: selection checkbox */}
//         <TableCell padding="none">
//             <Checkbox
//                 disabled={record.selectable}
//                 checked={selected}
//                 onClick={() => onToggleItem(id)}
//             />
//         </TableCell>
//         {/* data columns based on children */}
//         {React.Children.map(children, field => (
//             <TableCell key={`${id}-${field.props.source}`}>
//                 {React.cloneElement(field, {
//                     record,
//                     basePath,
//                     resource,
//                 })}
//             </TableCell>
//         ))}
//     </TableRow>
// );
//
// const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
// const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

export const BubbleList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return <List {...props} sort={{
        field: 'id', order: 'DESC'}}
                 title="用户列表"
                 exporter={false}
    >
        {isSmall? (
            <SimpleList
                primaryText={record => record.id}
                secondaryText={record => record.username}
            />
        ): (
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="username" label="手机号" />
                <TextField source="display_name" label="昵称" />
            </Datagrid>
        )}
    </List>
}

export const BubbleShow = ({permissions, ...props}) => {
    return <Show {...props}>
        <TabbedShowLayout>
            <Tab label="概览">
                <TextField label="游玩次数" source="play_time" />
                <TextField label="最后一次游玩时间" source="latest_time" />
                <TextField label="第一次游玩时间" source="first_time" />
            </Tab>
            <Tab label="第一次游玩数据">
                <TextField source="first_result.total_balloon_number" label="总气球个数"/>
                <TextField source="first_result.total_blow_times" label="总吹气次数"/>
                <TextField source="first_result.total_dollar" label="总收益"/>
                <TextField source="first_result.unexploded_balloon_number" label="未爆炸气球个数"/>
            </Tab>
            <Tab label="最后一次游玩数据">
                <TextField source="latest_result.total_balloon_number" label="总气球个数"/>
                <TextField source="latest_result.total_blow_times" label="总吹气次数"/>
                <TextField source="latest_result.total_dollar" label="总收益"/>
                <TextField source="latest_result.unexploded_balloon_number" label="未爆炸气球个数"/>
            </Tab>
            <Tab label="详细点击数据">
                <ArrayField source="stages">
                    <Datagrid>
                        <FunctionField label="当前游玩次数" render={ record => record.reset_time+1 } />
                        <FunctionField
                            label="是否爆炸"
                            render={
                                record => record.current_progress.current_frame === record.current_progress.explosion_int? "是": "否" }/>
                        <TextField label="用时（秒）" source="time_delta" />
                        <FunctionField label="点击次数" render={ record => record.click_delta_array.length } />
                        <TextField label="每次点击时长（秒）" source="click_delta_array" />
                        <TextField label="当前总气球个数" source="total_balloon_number" />
                        <TextField label="当前总吹气次数" source="total_blow_times" />
                        <TextField label="当前总收益" source="total_dollar" />
                        <TextField label="当前未爆气球数" source="unexploded_balloon_number" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
}
