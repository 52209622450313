import * as React from "react";
import {useMediaQuery} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Fragment, useState, useEffect} from 'react';
import {
    BooleanInput, Datagrid, EditButton, TextInput, Filter, List, SimpleForm, DateField,
    SimpleList, TextField, AutocompleteInput, useListContext, Pagination, SelectInput,
    FormDataConsumer, Loading, Error, useDataProvider, ReferenceField, Create, ReferenceInput, Edit
} from 'react-admin';
import ReactEcharts from 'echarts-for-react';
import {StaffModelCreate} from "./staffModel";
import {Card, CardActions, CardContent, CardHeader} from '@material-ui/core';
import {Form} from 'react-final-form';
import {checkPermission} from "./auth";
import FreeSoloCreateOption from "./CustomTextInput";

export const TagFilter = (props) => {
    return (<Filter {...props}>
        <ReferenceInput label="分类" source="catId" reference="tagcategories" allowEmpty filter={{parentId: '>0'}}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>)
}


export const TagList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const scaleRowClick = (id, basePath, record) => `/staffs/??displayedFilters=%7B"tag"%3Atrue%7D&filter=%7B"tag"%3A${id}%7D&order=ASC&page=1&perPage=10&sort=id`;
    return (
        <List {...props} sort={{
            field: 'id', order: 'DESC' }} title="标签列表" filters={<TagFilter/>} exporter={false}>
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.catId }
                    tertiaryText={ record => record.updateAt }/>
            ): (
                <Datagrid rowClick={scaleRowClick}>
                    <TextField label="ID" source="id" />
                    <TextField label="名称" source="name" />
                    <ReferenceField reference="tagcategories" source="catId" label="分类">
                    <TextField source="path" label="分类" />
                    </ReferenceField>
                    <DateField showTime={true} label="创建时间" source="createAt" />
                    <DateField showTime={true} label="更新时间" source="updateAt" />
                    <EditButton />
                </Datagrid>)}
        </List>
    );
}

export const TagCreate = (props) => {
    return (<Create {...props} title="标签创建">
        <SimpleForm>
            <TextInput source="name" label="名称" />
            <ReferenceInput source="catId" label="分类" reference="tagcategories" filter={{parentId: '>0'}}>
                <SelectInput optionText="path" />
            </ReferenceInput>
        </SimpleForm>
    </Create>);
}

export const TagEdit = (props) => {
    return (<Edit title="标签编辑" {...props}>
        <SimpleForm>
            <TextInput source="name" label="名称" />
            <ReferenceInput source="catId" label="分类" reference="tagcategories">
                <SelectInput optionText="path" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>);
}
