import * as React from "react";
import {Admin, Resource} from 'react-admin';
import {UserList, UserCreate, UserEdit} from "./users";
import {RoleList, RoleCreate, RoleEdit} from "./roles";
import {PermissionList, PermissionCreate, PermissionEdit} from "./permissions";
import {CompetenceModelCreate, CompetenceModelEdit, CompetenceModelList, CompetenceModelShow} from "./competencemodels";
import {JdCateList} from './jdcates';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import WorkOutlineSharpIcon from '@material-ui/icons/WorkOutlineSharp';
import AppsSharpIcon from '@material-ui/icons/AppsSharp';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import GamesIcon from '@material-ui/icons/Games';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MenuIcon from '@material-ui/icons/Menu';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import chineseMessages from 'ra-language-chinese';
// import englishMessages from "ra-language-english";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {checkPermission} from './auth'
import {BubbleList, BubbleShow} from "./bubbles";
import {StaffList} from "./staffs";
import {QuestionList, QuestionShow} from "./questions";
import {ScaleList} from "./scales";
import {DivisionList, DivisionShow} from "./divisions";
import {CharacterList, CharacterShow} from "./characters";
import {TagCreate, TagEdit, TagList} from "./tags";
import {TagCategoryCreate, TagCategoryEdit, TagCategoryList} from "./tagcategories";
import {ConfigCreate, ConfigEdit, ConfigList} from "./configs";
import {StrategyList, StrategyShow} from "./stragies";
import {PositionCreate, PositionEdit, PositionList} from "./positions";
import {TeamRoleModelCreate, TeamRoleModelEdit, TeamRoleModelList, TeamRoleShow} from "./TeamRoleModels";

const i18nProvider = polyglotI18nProvider(() => chineseMessages, 'cn');

const App = () => (
    <Admin
        i18nProvider={i18nProvider}
        locale="cn"
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}>
            {permissions => [
                checkPermission('list', 'users', permissions)?
                    <Resource
                        name="users"
                        list={UserList}
                        create={ checkPermission('create', 'users', permissions)? UserCreate: null }
                        edit={ checkPermission('edit', 'users', permissions)? UserEdit: null }
                        icon={PersonSharpIcon}
                        options={{label: '内部用户管理'}}
                    />: null,
                checkPermission('list', 'roles', permissions)?
                    <Resource
                        name="roles"
                        list={RoleList}
                        create={ checkPermission('create', 'roles', permissions)? RoleCreate: null}
                        edit={ checkPermission('edit', 'roles', permissions)? RoleEdit: null }
                        icon={PeopleSharpIcon}
                        options={{label: '角色管理'}}
                    />: null,
                checkPermission('list', 'permissions', permissions)?
                    <Resource
                        name="permissions"
                        list={PermissionList}
                        create={ checkPermission('create', 'permissions', permissions)? PermissionCreate: null}
                        edit={ checkPermission('edit', 'permissions', permissions)? PermissionEdit: null}
                        icon={VpnKeySharpIcon}
                        options={{label: '权限管理'}}
                    />: null,
                checkPermission('list', 'competencemodels', permissions)?
                    <Resource
                        name="competencemodels"
                        show={ CompetenceModelShow }
                        list={CompetenceModelList}
                        create={ checkPermission('create', 'competencemodels', permissions)? CompetenceModelCreate: null}
                        edit={ checkPermission('edit', 'competencemodels', permissions)? CompetenceModelEdit: null}
                        icon={AppsSharpIcon}
                        options={{label: '胜任力模型管理'}}
                    />: null,
                checkPermission('list', 'jdcates', permissions)?
                    <Resource
                        name="jdcates"
                        list={JdCateList}
                        icon={WorkOutlineSharpIcon}
                        options={{label: '职位列表'}}
                    />: null,
                checkPermission('list', 'bubbles', permissions)?
                    <Resource name="bubbles"
                              show={ BubbleShow }
                              list={BubbleList}
                              icon={GamesIcon}
                              options={{label: '小游戏'}}
                    />: null,
                checkPermission('list', 'staffs', permissions)?
                    <Resource name="staffs"
                              list={ StaffList }
                              icon={ PeopleSharpIcon }
                              options={{label: '外部用户数据管理'}}
                              />: null,
                checkPermission('list', 'questions', permissions)?
                    <Resource name="questions"
                              list={ QuestionList }
                              show={ QuestionShow }
                              icon={ QuestionAnswerIcon }
                              options={{label: '试题管理'}}
                    />: null,
                checkPermission('list', 'scales', permissions)?
                    <Resource name="scales"
                              list={ ScaleList }
                              icon={ ReceiptIcon }
                              options={{label: '量表管理'}}/>: null,
                checkPermission('list', 'divisions', permissions)?
                    <Resource name="divisions"
                              list={ DivisionList }
                              show={ DivisionShow }
                              icon={ MenuIcon }
                              options={{label:'维度列表'}}
                    />: null,
                checkPermission('list', 'characters', permissions)?
                    <Resource name="characters"
                              list={ CharacterList }
                              show={ CharacterShow }
                              icon={ MenuIcon }
                              options={{label:'特质列表'}}
                    />: null,
                checkPermission('list', 'tags', permissions)?
                    <Resource name="tags"
                              list={ TagList }
                              create={ TagCreate }
                              edit={ TagEdit }
                              icon={ LabelIcon }
                              options={{label:'标签列表'}}
                    />: null,
                checkPermission('list', 'tags', permissions)?
                    <Resource name="tagcategories"
                              list={TagCategoryList}
                              create={ TagCategoryCreate }
                              edit={ TagCategoryEdit }
                              icon={ LabelIcon }
                              options={{label: '标签分类列表'}}
                    />: null,
                checkPermission('list', 'configs', permissions)?
                    <Resource name="configs"
                              list={ ConfigList }
                              create={ ConfigCreate }
                              edit={ ConfigEdit }
                              icon={ SettingsIcon }
                              options={{label: '配置列表'}}
                     />:null,
                checkPermission('list', 'stragicthemes', permissions)?
                    <Resource name="stragicthemes"
                              list={StrategyList}
                              show={StrategyShow}
                              options={{label:'战略主题列表'}}
                    />:null,
                checkPermission('list', 'positions', permissions)?
                    <Resource name='positions'
                              list={PositionList}
                              create={PositionCreate}
                              edit={PositionEdit}
                              options={{label:'天赋职位关联管理'}}
                    />: null,
                checkPermission('list', 'teamrolemodels', permissions)?
                    <Resource name="teamrolemodels"
                              list={TeamRoleModelList}
                              create={TeamRoleModelCreate}
                              edit={TeamRoleModelEdit}
                              show={TeamRoleShow}
                              options={{label: '团队角色模型管理'}}
                    />: null
            ]}

    </Admin>
);

export default App;
