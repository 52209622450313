import * as React from "react";
import {useMediaQuery, Button} from '@material-ui/core';
import {
     Datagrid,  List, TabbedShowLayout, Tab, ImageField,
    SimpleList, TextField, ArrayField, Show, DateField, SelectField
} from 'react-admin';
import ReactEcharts from "echarts-for-react";
import {AutoMergeData} from "./common";
import {CustomImageField} from "./CustomImageField";


export const DivisionList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} title="维度列表" exporter={false} >
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.type }
                    tertiaryText={ record => record.id }/>
            ): (
                <Datagrid rowClick="show">
                    <TextField source="id" label="Id" />
                    <TextField source="name" label="名称" />
                    <TextField source="type" label="类型" />
                    <TextField source="code" label="编码" />
                    <TextField source="order" label="排序" />
                </Datagrid>
            )}
        </List>
    );
}

const DivisionScoreChart = ({record, source, style}) => {
    const scoreMap = record[source];
    const {keys, values} = AutoMergeData(scoreMap, 0.5);
    const legend = keys;
    const data = values;
    const option = {
        legend: {
            data: ['人数']
        },
        xAxis: {
            type: 'category',
            data: legend
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '人数',
            data: data,
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'black',
                            fontSize: 16
                        }
                    }
                }
            }
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />);
}


const DivisionScoreChart2 = ({record, source, style}) => {
    const scoreMap = record[source];
    const { keys, values } = AutoMergeData(scoreMap, 1);
    console.log(keys);
    console.log(values);
    const legend = keys;
    const data = values;
    const option = {
        legend: {
            data: ['人数']
        },
        xAxis: {
            type: 'category',
            data: legend
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '人数',
            data: data,
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'black',
                            fontSize: 16
                        }
                    }
                }
            }
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />);
}


export const DivisionShow = ({permissions, ...props}) => {

    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label="基本信息">
                <TextField source="name" label="名称" />
                <TextField source="type" label="类型" />
                <ArrayField source="descriptions" label="维度描述">
                    <Datagrid>
                        <SelectField source="type" label="描述类型" choices={[
                            { id: 'high', name: '高' },
                            { id: 'low', name: '低' },
                            { id: 'middle', name: '中' }
                        ]} />
                        <TextField source="content" label="描述内容" />
                    </Datagrid>
                </ArrayField>
                <DateField label="创建日期" source="createAt" />
            </Tab>
            <Tab label="记分规则">
                <ArrayField source="scoreRules" label="记分规则">
                    <Datagrid>
                        <TextField source="minOriginAverageValue" label="原始分数最小值" />
                        <TextField source="maxOriginAverageValue" label="原始分数最大值" />
                        <TextField source="zScore" label="最终分数" />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label='化身'>
                <ArrayField label="化身" source='figure' >
                    <Datagrid>
                        <TextField label='ID' source='id' />
                        <TextField label='名称' source='name' />
                        <CustomImageField label='图片' source='uri' width='100px' />
                        <TextField label='性别' source='gender' />
                        <TextField label='诗句' source='poem' />
                        <TextField label='描述' source='description' />
                        <TextField label='HR描述' source='hrDescription' />
                        <TextField label='创建时间' source='createAt' />
                        <TextField label='更新时间' source='updateAt' />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="用户分数统计">
                <DivisionScoreChart source="scoreMap" style={{
                    minHeight: '600px'}} />
                <DivisionScoreChart2 source="scoreMap" style={{
                    minHeight: '600px'}} />
            </Tab>
        </TabbedShowLayout>
    </Show>)
}
