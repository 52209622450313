import * as React from "react";
import {useMediaQuery, Button} from '@material-ui/core';
import {
    Datagrid,
    List,
    Edit,
    Tab,
    SimpleList,
    TextField,
    SelectField,
    DateField, useQuery,
    Create, Loading, Error, ArrayField, FunctionField,
    SimpleForm, BooleanInput, Show, SimpleShowLayout, TabbedShowLayout,
    TextInput, ArrayInput, SimpleFormIterator,
    SelectInput, EditButton, ReferenceArrayInput, SelectArrayInput, ReferenceInput, NumberInput
} from 'react-admin';
import ListActions from "./ListActions";
import {AutoMergeData} from "./common";
import ReactEcharts from "echarts-for-react";
import {required} from "ra-core";


const DivisionTags = ({record}) => {
        if(!record.raw){
            return null;
        }
        return (
            <ul style={{
                margin:'0', padding:'0'}}>
                {record.raw.map(elem => (
                    <li key={elem.id} style={{listStyle: 'none'}}>{elem.name}{elem.reverse? '(反向)': ''}-权重:{elem.weight}</li>
                ))}
            </ul>
        );
}

const Boundary = ({record, source}) => {
    if(!record[source]){
        return null;
    }
    const {low, high} = record[source]
    return (
        <ul style={{
            margin:'0',padding:'0', width: '80px'}}>
            <li style={{listStyle: 'none'}}>低:[-∞, {low})</li>
            <li style={{listStyle: 'none'}}>中:[{low}, {high})</li>
            <li style={{listStyle: 'none'}}>高:[{high}, +∞)</li>
        </ul>
    );
}


export const TeamRoleModelList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} title="团队角色模型列表" sort={{
            field: 'id', order: 'DESC' }}
              actions={<ListActions resource='teamrolemodels' />}
        >
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.summary }
                    tertiaryText={ record => record.createAt }/>
            ): (
                <Datagrid rowClick="show">
                    <TextField source="id" label="Id" />
                    <TextField source="name" label="名称" />
                    <TextField source="summary" label="概述" />
                    <TextField source="description" label="描述" />
                    <DivisionTags source="raw" label="内容"  />
                    <Boundary source="level" label="等级划分" />
                    <DateField source="createAt" showTime={true} label="创建时间"/>
                    <DateField source="updateAt" showTime={true} label="修改时间"/>
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const TeamRoleModelCreate = (props) => {
    return (<Create {...props} title="团队角色模型创建">
        <SimpleForm>
            <TextInput source="name" label="名称" validate={required()} />
            <TextInput source="summary" label="概述" validate={required()} />
            <TextInput source="description" multiline label="描述" />
            <NumberInput source="level.low" label="低中分界" />
            <NumberInput source="level.high" label="中高分界" />
            <ArrayInput source="raw" label="内容">
                <SimpleFormIterator>
                    <ReferenceInput
                        label="维度"
                        source="id"
                        reference="divisions"
                        perPage={100}
                        allowNull
                        allowEmpty
                        filter={{type: ['value', 'cartel']}}
                        format={(v) => (!v ? null : v)}>
                        <SelectInput optionText={(record) => `${record.name}[${record.type}]`} optionValue="id" />
                    </ReferenceInput>
                    <BooleanInput label="是否反向计分" source="reverse" />
                    <NumberInput label="权重" source="weight" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>);
}

export const TeamRoleModelEdit = (props) => {
    return (<Edit {...props} title="团队角色模型修改">
        <SimpleForm>
            <TextInput source="name" label="名称" validate={required()} />
            <TextInput source="summary" label="概述" validate={required()} />
            <TextInput source="description" multiline label="描述" />
            <NumberInput source="level.low" label="低中分界" />
            <NumberInput source="level.high" label="中高分界" />
            <ArrayInput source="raw" label="内容">
                <SimpleFormIterator>
                    <ReferenceInput
                        label="维度"
                        source="id"
                        reference="divisions"
                        perPage={100}
                        allowNull
                        allowEmpty
                        filter={{type: ['value', 'cartel']}}
                        format={(v) => (!v ? null : v)}>
                        <SelectInput optionText={(record) => `${record.name}[${record.type}]`} optionValue="id" />
                    </ReferenceInput>
                    <BooleanInput label="是否反向计分" source="reverse" />
                    <NumberInput label="权重" source="weight" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>);
}


const TeamRoleScoreDistributeChart = ({record, source, style}) => {
    const scoreMap = record[source];
    const {keys, values} = AutoMergeData(scoreMap, 1);
    const legend = keys;
    const data = values;
    const option = {
        legend: {
            data: ['人数']
        },
        xAxis: {
            type: 'category',
            data: legend
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '人数',
            data: data,
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'black',
                            fontSize: 16
                        }
                    }
                }
            }
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />);
}


export const TeamRoleShow = (props) => {
    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label="基本信息">
                <TextField source="name" label="名称"/>
                <TextField source="summary" label="概述" />
                <TextField source="description" label="描述" />
                <Boundary source="level" label="等级划分" />
                <ArrayField source="raw" label="内容"  >
                    <Datagrid>
                        <FunctionField render={ record => record.name+(record.reverse? '(反向)': '') } label="维度" />
                        <TextField source="weight" label="权重" />
                    </Datagrid>
                </ArrayField>
                <DateField source="createAt" showTime={true} label="创建时间"/>
                <DateField source="updateAt" showTime={true} label="修改时间"/>
            </Tab>
            <Tab label="用户数据">
                <TeamRoleScoreDistributeChart source="scoreMap" />
            </Tab>
        </TabbedShowLayout>
    </Show>);
}
