import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    SimpleList,
    EditButton,
    SelectInput,
    TabbedForm,
    FormTab,
    Create,
    Edit,
    ReferenceArrayField,
    TextInput,
    SelectArrayInput,
    ReferenceArrayInput,
    SingleFieldList,
    ChipField,
    useListContext, useNotify, fetchStart, fetchEnd, TopToolbar, sanitizeListRestProps
} from 'react-admin';
import {useMediaQuery} from "@material-ui/core";
import {cloneElement, useState} from "react";
import {apiUrl, getToken} from "./dataProvider";
import Button from "@material-ui/core/Button";


export const UserList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List {...props}
              sort={{
                  field: 'id', order: 'DESC' }}
              title="用户列表"
              exporter={false}
        >
            {isSmall? (
                <SimpleList
                    primaryText={record => record.userName}
                    secondaryText={record => record.phone}
                />
            ): (
                <Datagrid>
                    <TextField source="id"/>
                    <TextField label="用户名" source="userName"/>
                    <EmailField label="邮箱" source="email"/>
                    <TextField label="手机" source="phone"/>
                    <TextField label="姓名" source="fullName"/>
                    <ReferenceArrayField label="角色" reference="roles" source="roles">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <EditButton />
                </Datagrid>
            )}

        </List>
    )
};

export const UserCreate = (props) => {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="基本信息">
                    <TextInput source="userName" />
                    <TextInput source="fullName" />
                    <TextInput source="phone" />
                    <TextInput source="email" />
                    <TextInput source="password" />
                    <SelectInput source="disabled" choices={[
                        { id: false, name: '启用'},
                        { id: true, name: '禁用'}
                    ]}/>
                </FormTab>
                <FormTab label="分配角色">
                    <ReferenceArrayInput source="roles" reference="roles">
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export const UserEdit = (props) => {
    return (
        <Edit {...props} title="编辑用户">
            <TabbedForm>
                <FormTab label="基本信息">
                    <TextInput source="userName" />
                    <TextInput source="fullName" />
                    <TextInput source="phone" />
                    <TextInput source="email" />
                    <TextInput source="password" />
                    <SelectInput source="disabled" choices={[
                        { id: false, name: '启用'},
                        { id: true, name: '禁用'}
                    ]}/>
                </FormTab>
                <FormTab label="分配角色">
                    <ReferenceArrayInput source="roles" reference="roles">
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}
