import * as React from "react";
import {useMediaQuery, makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Fragment, useState, useEffect} from 'react';
import {
    ChipField, Datagrid, FunctionField, TextInput, Filter, List, AutocompleteArrayInput,
    SimpleList, TextField, AutocompleteInput, useListContext, Pagination, ReferenceArrayInput, SingleFieldList,
    BulkDeleteButton, Loading, Error, useDataProvider, ReferenceInput, SelectInput as Select, ArrayField, useCreate,
    useNotify, useRefresh
} from 'react-admin';
import ReactEcharts from 'echarts-for-react';
import {StaffModelCreate} from "./staffModel";
import {Card, CardActions, CardContent, CardHeader, Chip } from '@material-ui/core';
import {Form, useFormState} from 'react-final-form';
import {checkPermission} from "./auth";
import ListActions from "./ListActions";
import * as antd  from 'antd';

const teamRoleScoreTableStyles = makeStyles({
    table: {
        border: '1px solid black',
        margin: '15px'
    },
    tr: {
        textAlign: 'center',
    },
    td: {
        minWidth: '200px',
        textAlign: 'center',
        border: '1px solid black',
        padding: '5px'
    }
});


const StaffPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;
const StafCardListPage = antd.Pagination

export const StaffFilter = (props) => {
    const sexChoices = [
        {id: 'm', name: '男'},
        {id: 'f', name: '女'}
    ];
    const degreeChoices = [
        {id: "未知", name: "未知"},
        {id: "小学", name: "小学"},
        {id: "初中", name: "初中"},
        {id: "中专", name: "中专"},
        {id: "高中", name: "高中"},
        {id: "大专", name: "大专"},
        {id: "本科", name: "本科"},
        {id: "硕士", name: "硕士"},
        {id: "博士", name: "博士"},
    ];
    const expChoices = [
        {id: "应届生", name: "应届生"},
        {id: "1-3年", name: "1-3年"},
        {id: "3-5年", name: "3-5年"},
        {id: "5-10年", name: "5-10年"},
        {id: "10年以上", name: "10年以上"},
        {id: "未知", name: "未知"},
    ];
    const sourceChoices = [
        {id: 1, name: "九公里"},
        {id: 2, name: "中天"}
    ];
    return (<Filter {...props}>
        <TextInput source="name" label="姓名"/>
        <TextInput source="phone" label="手机号"/>
        <TextInput source="company_name" label="公司名称"/>
        <TextInput source="jd_title" label="应聘职位"/>
        <TextInput source="branch_name" label="部门名称"/>
        <AutocompleteInput label="性别" source="sex" choices={sexChoices}/>
        <AutocompleteInput label="学历" source="degree" choices={degreeChoices}/>
        <AutocompleteInput label="工作经验" source="exp" choices={expChoices}/>
        <AutocompleteInput label="来源" source="source" choices={sourceChoices}/>
        <AutocompleteArrayInput label="答题状态" source="test_status" choices={[
            {id: 1, name: "16pf"},
            {id: 2, name: "天赋"},
            {id: 4, name: "价值观"}
        ]}/>
        <ReferenceInput reference="tags" label="标签" source="tag">
            <Select optionText="name" />
        </ReferenceInput>
    </Filter>)
};

const WorkExperienceField = ({record}) => {
    const workExperiences = record.work_experience;
    const uls = workExperiences.map((item, index) => {
        const items = Object.entries(item).map(([k, v], _) => {
            if (v) {
                return (<li key={k}>
                    {v}
                </li>)
            }
            return null;
        })
        return (<ul key={index}>
            {items}
        </ul>);
    });
    return (<div style={{
        width: '100%', display: 'flex', flexFlow: 'row'}}>
        {uls}
    </div>);
}
WorkExperienceField.defaultProps = {
    addLabel: true
};

const DivisionScoreField = ({record}) => {
    const divisionScores = record.division_scores;
    if (!divisionScores || divisionScores.length === 0) {
        return null;
    }
    console.log(divisionScores);
    const values = divisionScores.map(item => item.score)
    const indicator = divisionScores.map(item => {
        const color = item.id <= 23 ? 'red' : (item.id > 39 ? 'green' : 'blue')
        return {name: item.name, max: item.max, color}
    })
    const option = {
        title: {},
        tooltip: {},
        legend: {},
        radar: {
            // shape: 'circle',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    padding: [3, 5]
                }
            },
            indicator,
        },
        series: [{
            // name: '用户分数',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: values,
                    name: ''
                }
            ]
        }]
    };
    return (<ReactEcharts
        option={option}
        style={{
            height: '600px', width: '100%'}}
    />)
}
DivisionScoreField.defaultProps = {
    addLabel: true
}


const CharacterScoreField = ({record}) => {
    const characterScores = record.character_scores;
    if (!characterScores || characterScores.length === 0) {
        return null;
    }
    const values = characterScores.map(item => item.score);
    const indicator = characterScores.map(item => {
        const color = item.id <= 8 ? 'red' : (item.id > 11 ? 'blue' : 'green')
        return {name: item.name, max: item.max, color}
    })
    const option = {
        title: {},
        tooltip: {},
        legend: {},
        radar: {
            // shape: 'circle',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    padding: [3, 5]
                }
            },
            indicator,
        },
        series: [{
            // name: '用户分数',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: values,
                    name: ''
                }
            ]
        }]
    };
    return (<ReactEcharts
        option={option}
        style={{
            height: '600px', width: '100%'}}
    />)
}
CharacterScoreField.defaultProps = {
    addLabel: true
}

const TagLinkFormBody = ({handleSubmit, record}) => {
    const {values} = useFormState();
    let params = {basicId: record.id, tagIds: values.tags};
    if(typeof values.tags === 'undefined'){
        params.tagIds = [];
    }
    const notify = useNotify();
    const refresh = useRefresh();
    let [createModel] = useCreate('taguserlinks', params,  {
        onSuccess: () => {
            notify('赋予标签成功！');
            refresh();
        },
        onFailure: error => notify('赋予标签失败！')
    });

    return (<form onSubmit={handleSubmit} style={{marginTop: '10px'}}>
        <ReferenceArrayInput reference="tags" source="tags" resource="tags" label="选择标签">
            <AutocompleteArrayInput optionText='name' optionValue='id' />
        </ReferenceArrayInput>
        <Button variant="contained" color="primary" onClick={createModel}>保存</Button>
    </form>);
}

const TagLinkForm = ({userId, record}) => {
    return (<Form onSubmit={(value) => {
        console.log(userId, value);
    }} component={TagLinkFormBody} record={record}/>);
}


export const StaffPanel = ({id, record, resource}) => {
    console.log(record['team_role_score']);
    const styles = teamRoleScoreTableStyles();
    const levelMap = {'low': '低', 'middle': '中', 'high': '高'};
    return (
        <div style={{
            display: 'flex', flexFlow: 'column', itemAlign: 'center', justifyContent: 'center'}}>
            <WorkExperienceField style={{width: '100%'}} label="求职经历" record={record}/>
            <div style={{
                display: 'flex', width: '100%', flexFlow: 'row'}}>
                <CharacterScoreField style={{width: '50%'}} label="特质分数" record={record}/>
                <DivisionScoreField style={{width: '50%'}} label="维度分数" record={record}/>
            </div>
            {
                record['team_role_score']? (<div style={{width: '50%'}}>
                    <table className={styles.table} cellPadding="0" cellSpacing="0">
                        <tr className={styles.tr}>
                            <th className={styles.td} colSpan="3">用户角色匹配分数详情</th>
                        </tr>
                        <tr className={styles.tr}>
                            <th className={styles.td}>名称</th>
                            <th className={styles.td}>等级</th>
                            <th className={styles.td}>分数</th>
                        </tr>
                        { record['team_role_score'].map(item => {
                            return (<tr className={styles.tr}>
                                <td className={styles.td}>{item.name}</td>
                                <td className={styles.td}>{levelMap[item.level]?levelMap[item.level]:''}</td>
                                <td className={styles.td}>{item.score}</td>
                            </tr>)
                        }) }
                    </table>
                </div>): null
            }
            <TagLinkForm userId={id} record={record}/>
        </div>
    );
}

// export const CreateModelButton = ({selectedIds}) => {
//     const notify = useNotify();
//     const {data} = useListContext();
//     const type = selectedIds.map(id => data[id]).reduce((sum, staff) => sum & staff.test_status, 7);
//     let [createModel, {loading}] = useCreate('staffmodels', {ids: selectedIds, type}, {
//         onSuccess: () => {
//             notify('新胜任力模型创建成功！')
//         },
//         onFailure: error => notify('新胜利力模型创建失败！')
//     });
//     if (selectedIds.length < 5) {
//         loading = true;
//     }
//     return (<Button variant="contained" color="primary"
//                     label="simple.action.createModel"
//                     disabled={loading}
//                     onClick={createModel}
//     >
//         胜任力建模
//         <CreateIcon/>
//     </Button>);
// }

export const StaffAside = () => {
    let staffList = [];
    const {data, selectedIds} = useListContext();
    const result = selectedIds.map(id => data[id]).reduce((sum, staff) => sum & staff.test_status, 7)
    const string = `${result.toString(2).padStart(3, '0')}`.split('')
    const typeArr = [{id: 4, name: '价值观'}, {id: 2, name: '天赋'}, {id: 1, name: '16pf'}]
    const finalArr = string.map((item, index) => {
        return item === '1' ? typeArr[index] : null;
    }).filter(item => item !== null)
    staffList = staffList.concat(selectedIds.map(id => data[id]));
    return (<div style={{
        width: 200, margin: '1em'}}>
        <Typography variant="h6">可建模类型：</Typography>
        <Typography variant="body2" style={{color: result === 0 ? 'red' : 'black'}}>
            {result === 0 ? '缺少可以建模的共同维度' : finalArr.map(item => (
                <ChipField key={item.id} record={item} source="name"/>))}
        </Typography>
        <Typography variant="h6">
            建模人员列表：
        </Typography>
        {
            staffList.map(staff => (<ChipField key={staff.id} record={staff} source="name"/>))
        }
    </div>)
};

export const StaffBulkActionButtons = ({permissions, ...props}) => {
    return (<Fragment>
        <div style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center'}}>
            {/*<CreateModelButton  {...props}/>*/}
            <StaffModelCreate basePath='/staffmodels' resource="staffmodels"/>
            {
                checkPermission('delete', 'staffs', permissions)? (<BulkDeleteButton {...props} />): null
            }
        </div>
    </Fragment>);
};

const TeamRoleChips = ({record, source}) => {
    const data = record[source];
    if(!data){
        return null;
    }
    return (<div style={{display: 'block',
        maxWidth: '320px'}}>
        <div style={{display: 'flex',
            flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
        {
            data.filter(item => item.level === 'high').map(item => {
                return (<div key={item.id} style={{ width: 'auto' , padding: '0px',
                    listStyle: 'None', display: 'flex'}}>
                    <Chip style={{margin: '4px'}} color={item.level === 'low'? 'default': item.level === 'high'? 'primary': 'secondary' } label={`${item.name}(${Math.round(item.score)})`} />
                </div>);
            })
        }
        </div>
    </div>);

}

export const StaffList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (<List {...props}
                  filters={<StaffFilter/>}
                  bulkActionButtons={<StaffBulkActionButtons permissions={permissions}/>}
                  aside={<StaffAside/>}
                  pagination={<StaffPagination/>}
                  actions={<ListActions/>}
                  exporter={false}
    >
        {isSmall ? (<SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.phone}
            tertiaryText={record => `${record.sex === 'm' ? '男' : '女'}`}/>) : (
            <Datagrid expand={<StaffPanel/>}>
                {/*<TextField source="id" label="ID"/>*/}
                <TextField source="name" label="姓名"/>
                <TextField source="phone" label="手机"/>
                <FunctionField label="性别" render={record => `${record.sex === 'm' ? '男' : '女'}`}/>
                <TextField source="degree" label="学历"/>
                <TextField source="exp" label="经验"/>
                <FunctionField label="数据来源" render={record => `${record.source === 1 ? '九公里' : '中天'}`}/>
                <FunctionField label="答题状态" render={record => {
                    const string = `${record.test_status.toString(2).padStart(3, '0')}`.split('')
                    const typeArr = ['价值观', '天赋', '16pf']
                    const finalArr = string.map((item, index) => {
                        return item === '1' ? typeArr[index] : null;
                    }).filter(item => item !== null);
                    // console.log(record.test_status, string, finalArr);
                    return finalArr.map((item, index) => {
                        const record = {'name': item}
                        return (<ChipField key={index} record={record} source="name"/>);
                    });
                }}/>
                <ArrayField source='tags' label="标签">
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ArrayField>
                <TeamRoleChips source="team_role_score" label="团队角色"  />
                {/*<WorkExperienceField label="求职经历" />*/}
                {/*<DivisionScoreField label="维度分数" />*/}
            </Datagrid>)}
    </List>)
};

const cardStyle = {
    width: 500,
    minHeight: 80,
    margin: '0.3em',
    display: 'inline-block',
    verticalAlign: 'top'
};


export const StaffCardList = ({record, source, staffs, setStaffs, setSeries}) => {
    // console.log(staffs);
    const model = JSON.parse(record[source]);
    const id = record['id'];
    let test_status = [];
    if (model.some(item => item.id <= 23)) {
        test_status.push(4);
    }
    if (model.some(item => item.id > 39)) {
        test_status.push(2);
    }
    if (model.some(item => item.id <= 39 && item.id > 23)) {
        test_status.push(1);
    }
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [payload, setPayload] = useState({
        pagination: {page: 1, perPage: 4},
        sort: {field: 'id', order: 'DESC'}, filter: {test_status: test_status}
    })
    const [total,setTotal] = useState(0)

    useEffect(() => {
        dataProvider.getList('staffs', payload).then(({data,total}) => {
            setTotal(total)
            const ids = data.map(item => item.id)
            dataProvider.getMany(`match/${id}`, { ids }).then((matches) => {
                const matchMap = new Map();
                matches.data.forEach((item, index) => {
                   matchMap[item.id] = Math.round(item.match*100).toString() + '%';
                });
                setData(data.map(item => Object.assign(item, {'match': matchMap[item.id]})));
                setLoading(false)
            }).catch(error => {
                setError(error)
                setLoading(false)
            })
        })
            .catch(error => {
                setError(error);
                setLoading(false)
            })
    }, [id, dataProvider, payload])

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;


    const handleProject = (staff) => {
        const oldStaff = staffs.find(item => item.id === staff.id)
        if (!oldStaff) {
            staffs.push(staff)
        }
        setStaffs(staffs)
        const keys = model.map(item => item.id);
        const seriesStaff = staffs.map(item => {
            const divisionScores = item.division_scores;
            const scoreMap = {};
            divisionScores.forEach((item) => {
                scoreMap[item.id] = item.score;
            });
            return {value: keys.map(item => scoreMap[item]), name: item.name ? item.name : item.phone}
        })
        setSeries((_) => seriesStaff)
    }

    const lists = data.map(staff => {
        return (<Card key={staff.id} style={cardStyle}>
            <div style={{
                display: 'flex', flexFlow: 'row' }}>
                <div style={{ width: '60%' }}>
                    <CardHeader
                        title={<TextField record={staff} source="name"/>}
                        subheader={<TextField record={staff} source="phone"/>}
                    />
                </div>
                <div style={{
                    width: '40%', textAlign: 'center' }}>
                    <CardContent>
                        <TextField label="匹配度" record={staff} source="match" />
                    </CardContent>
                </div>
            </div>
            {
                staff.work_experience.map((item, index) => {
                    return (
                        <CardContent style={{display: 'flex'}} key={index}>
                            <TextField record={item} label="公司名称" source="company_name"/>&nbsp;
                            <TextField record={item} label="职位" source="jd_title"/>&nbsp;
                            <TextField record={item} label="部门" source="branch_name"/>&nbsp;
                        </CardContent>
                    );
                })
            }
            <CardActions style={{textAlign: 'right'}}>
                <Button color="primary" variant="contained" onClick={() => handleProject(staff)}>投射</Button>
            </CardActions>
        </Card>);
    });
    const formBody = ({handleSubmit}) => {
        return (<form onSubmit={handleSubmit} style={{
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            height: '50px'
        }}>
            <TextInput source="q" label="搜索" resettable={true}/>
            <Button variant="contained" type="submit" color="primary">搜索</Button>
        </form>);
    }

    const onChange = (page,pageSize)=>{
        console.log(page,pageSize);
        // 分页改变后重新调用接口重新渲染数据
        setPayload(
            {...payload,pagination:{page:page,perPage:pageSize}}
        )
    }
    return (<div style={{overflowY:'auto',height:'700px'}}>
        <Form onSubmit={(val) => {
            setPayload(prevState => ({...prevState, filter: {...prevState.filter, q: val.q}}));
            setSeries([]);
            setStaffs([]);
        }} component={formBody}/>
        {lists}

        {<StafCardListPage onChange={onChange} defaultCurrent={1} pageSizeOptions={[4,5,10,15]} total={total} pageSize={payload.pagination.perPage} />}

    </div>);
};
