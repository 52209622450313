import * as React from "react";
import {useMediaQuery, Button} from '@material-ui/core';
import {
    Datagrid,
    List,
    Edit,
    Tab,
    SimpleList,
    TextField,
    SelectField,
    Create,
    SimpleForm,
    TextInput,
    SelectInput, EditButton
} from 'react-admin';
import ListActions from "./ListActions";

export const PositionList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} title="天赋职位列表" sort={{
            field: 'id', order: 'DESC' }}
              actions={<ListActions resource='positions' />}
        >
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => `逻辑推理:${record.logical},空间推理:${record.space},数字推理:${record.digital}` }
                    tertiaryText={ record => `ID:${record.id}` }/>
            ): (
                <Datagrid>
                    <TextField source="id" label="Id" />
                    <TextField source="name" label="名称" />
                    <SelectField source="logical" label="逻辑推理" choices={[
                        { id: 'high', name: '高' },
                        { id: 'middle', name: '中' },
                        { id: 'low', name: '低' }
                    ]} />
                    <SelectField source="space" label="空间推理" choices={[
                        { id: 'high', name: '高' },
                        { id: 'middle', name: '中' },
                        { id: 'low', name: '低' }
                    ]} />
                    <SelectField source="digital" label="数字推理" choices={[
                        { id: 'high', name: '高' },
                        { id: 'middle', name: '中' },
                        { id: 'low', name: '低' }
                    ]} />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const PositionCreate = (props) => {
    return (<Create {...props} title="天赋与职位关联创建">
        <SimpleForm>
            <TextInput source="name" label="名称" />
            <SelectInput label='逻辑推理' source='logical' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
            <SelectInput label='空间推理' source='space' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
            <SelectInput label='数字推理' source='digital' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
        </SimpleForm>
    </Create>);
}

export const PositionEdit = (props) => {
    return (<Edit {...props} title="天赋与职位关联修改">
        <SimpleForm>
            <TextInput source="name" label="名称" />
            <SelectInput label='逻辑推理' source='logical' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
            <SelectInput label='空间推理' source='space' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
            <SelectInput label='数字推理' source='digital' choices={[
                { id: 'high', name: '高' },
                { id: 'middle', name: '中' },
                { id: 'low', name: '低' }
            ]}/>
        </SimpleForm>
    </Edit>);
}
