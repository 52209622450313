import * as React from "react";
import {useMediaQuery} from '@material-ui/core';
import {
    Datagrid,
    FunctionField,
    Filter,
    List,
    SimpleList,
    TextField,
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    ArrayField,
    SearchInput,
    SelectInput,
    ReferenceInput,
    DateField
} from 'react-admin';
import {CustomImageField} from "./CustomImageField";


export const QuestionFilter = (props) => {
    return (<Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput label="量表" source="scaleId" reference="scales" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>);
}


export const QuestionList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}
            filters={<QuestionFilter/>}
              sort={{
            field: 'id', order: 'DESC' }}
              title="题目列表"
              exporter={false}
        >
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.content }
                    secondaryText={ record => record.reverseContent }
                    tertiaryText={ record => record.type }/>
                ): (
                    <Datagrid rowClick="show">
                <TextField label="ID" source="id" />
                <SelectField label="类型" source="type" choices={[
                    { id: 'singleChoice', name: '单选' },
                    { id: 'multipleChoice', name: '多选' },
                    { id: 'verifyChoice', name: '验证题目' },
                    { id: 'blank', name: '填空' },
                    { id: 'supplement', name: '题目补充项' },
                    { id: 'parentQuestion', name: '父子问题' },
                ]} />
                <ReferenceField label="量表" reference="scales" source="scaleId" >
                    <TextField source="name" />
                </ReferenceField>
                <FunctionField label="题目" render={
                    record =>
                        record.showStyle === 'image'? (
                            record.isSVG?
                                (<img
                                    alt=''
                                    style={{ width: '100px' }}
                                    src={"data:image/svg+xml;base64," + record.content }
                                />):
                                (<img
                                    alt=''
                                    style={{ width: '100px' }}
                                    src={record.content} />)): record.content }/>
                <TextField label="反向内容" source="reverseContent" />
                <SelectField label="展示形式" source="showStyle" choices={[
                    { id: 'text', name: '文字' },
                    { id: 'image', name: '图片' }
                ]} />
                <TextField label="难度系数" source="dod" />
                <FunctionField label="提示" render={
                    record =>
                        record.tip && record.tip.substr(0, 5) === 'https'?
                            (<img alt='' src={ record.tip } style={{ width: '100px' }} />): record.tip } />
                <DateField showTime={true} label="创建日期" source="createAt" />
                <DateField showTime={true} label="更新日期" source="updateAt" />
            </Datagrid>)}
        </List>
    );
}

export const QuestionShow = ({permissions, ...props}) => {
    return (<Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <SelectField label="类型" source="type" choices={[
                { id: 'singleChoice', name: '单选' },
                { id: 'multipleChoice', name: '多选' },
                { id: 'verifyChoice', name: '验证题目' },
                { id: 'blank', name: '填空' },
                { id: 'supplement', name: '题目补充项' },
                { id: 'parentQuestion', name: '父子问题' },
            ]} />
            <ReferenceField label="量表" reference="scales" source="scaleId" >
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="题目" render={
                record =>
                    record.showStyle === 'image'?
                        (<CustomImageField record={ record } source="content" width="100px"/>):
                        record.content }/>
            <TextField label="反向内容" source="reverseContent" />
            <SelectField label="展示形式" source="showStyle" choices={[
                { id: 'text', name: '文字' },
                { id: 'image', name: '图片' }
            ]} />
            <TextField label="难度系数" source="dod" />
            <FunctionField label="提示" render={
                record =>
                    record.tip && record.tip.substr(0, 5) === 'https'?
                        (<CustomImageField record={record} source="tip" width="100px" />): record.tip } />
            <ArrayField source="options" label="选项">
                <Datagrid>
                    <TextField source="id" label="ID" />
                    <FunctionField label="内容" render={
                        record =>
                            record.showStyle === 'image'?(
                                <CustomImageField record={record} source="content" width="100px"/>
                                ): record.content }/>
                    <TextField source="score" label="分数" />
                    <TextField source="createAt" label="创建时间" />
                    <TextField source="updateAt" label="更新时间" />
                </Datagrid>
            </ArrayField>
            <TextField label="创建日期" source="createAt" />
            <TextField label="更新日期" source="updateAt" />
        </SimpleShowLayout>
    </Show>)
}
