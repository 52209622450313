import * as React from "react";
import {useMediaQuery} from '@material-ui/core';
import {
    Datagrid, List,TabbedShowLayout, Tab,
    SimpleList, TextField, ArrayField, SelectField,
    Show, DateField
} from 'react-admin';
import ReactEcharts from "echarts-for-react";
import {AutoMergeData} from "./common";

export const CharacterList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} title="特质列表" exporter={false}>
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.negativeName }
                    tertiaryText={ record => record.createAt }/>
            ): (
                <Datagrid rowClick="show">
                    <TextField source="id" label="Id" />
                    <TextField source="name" label="名称" />
                    <TextField source="negativeName" label="反向名称" />
                    <TextField source="maxScore" label="最大分数" />
                    <TextField source="minScore" label="最小分数" />
                    <DateField source="createAt" label="创建日期" />
                    <DateField source="updateAt" label="更新日期" />
                </Datagrid>
            )}
        </List>
    );
}


const CharacterScoreChart = ({record, source, style}) => {
    const scoreMap = record[source];
    const {keys, values} = AutoMergeData(scoreMap, 0.5);
    const legend = keys;
    const data = values;
    const option = {
        legend: {
            data: ['人数']
        },
        xAxis: {
            type: 'category',
            data: legend
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '人数',
            data: data,
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'black',
                            fontSize: 16
                        }
                    }
                }
            }
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />);
}


const CharacterScoreChart2 = ({record, source, style}) => {
    const scoreMap = record[source];
    const {keys, values} = AutoMergeData(scoreMap, 1);
    const legend = keys;
    const data = values;
    const option = {
        legend: {
            data: ['人数']
        },
        xAxis: {
            type: 'category',
            data: legend
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: '人数',
            data: data,
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: 'black',
                            fontSize: 16
                        }
                    }
                }
            }
        }]
    };
    return (<ReactEcharts
        option={option}
        style={style}
    />);
}



export const CharacterShow = ({permissions, ...props}) => {
    return (<Show {...props}>
        <TabbedShowLayout>
            <Tab label='基本信息'>
                <TextField source="name" label="名称" />
                <TextField source="negativeName" label="反向名称" />
                <TextField source="maxScore" label="最大分数" />
                <TextField source="minScore" label="最小分数" />
                <ArrayField source="descriptions" label="特质描述">
                    <Datagrid>
                        <SelectField source="type" label="描述类型" choices={[
                            { id: 'high', name: '高' },
                            { id: 'low', name: '低' },
                            { id: 'middle', name: '中' }
                        ]} />
                        <TextField source="content" label="描述内容" />
                    </Datagrid>
                </ArrayField>
                <ArrayField source="divisions" label="包含的维度信息">
                    <Datagrid>
                        <TextField source="divisionId" label="维度ID" />
                        <TextField source="name" label="名称" />
                        <SelectField source="type" label="正向或者反向" choices={[
                            { id: 'positive', name: '正向' },
                            { id: 'negative', name: '反向' }
                        ]} />
                        <TextField source="weight" label="权重" />
                    </Datagrid>
                </ArrayField>
                <DateField source="createAt" label="创建日期" />
                <DateField source="updateAt" label="更新日期" />
            </Tab>
            <Tab label='用户分数统计'>
                <CharacterScoreChart source='scoreMap' style={{
                    minHeight: '600px'}}/>
                <CharacterScoreChart2 source='scoreMap' style={{
                    minHeight: '600px'}}/>
            </Tab>
        </TabbedShowLayout>
    </Show>)
}
