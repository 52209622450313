import * as React from "react";
import {useMediaQuery} from '@material-ui/core';
import {
    Datagrid, List,
    SimpleList, TextField, DateField
} from 'react-admin';


export const ScaleList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const scaleRowClick = (id, basePath, record) => `/questions/?displayedFilters=%7B"scaleId"%3Atrue%7D&filter=%7B"scaleId"%3A${id}%7D&order=DESC&page=1&perPage=10&sort=id`;
    return (
        <List {...props} sort={{
            field: 'id', order: 'DESC' }} title="题目列表" exporter={false}>
            {isSmall? (
                <SimpleList
                    primaryText={ record => record.name }
                    secondaryText={ record => record.version }
                    tertiaryText={ record => record.updateAt }/>
            ): (
                <Datagrid rowClick={scaleRowClick}>
                    <TextField label="ID" source="id" />
                    <TextField label="名称" source="name" />
                    <TextField label="版本" source="version" />
                    <TextField label="testId" source="testId" />
                    <DateField showTime={true} label="创建时间" source="createAt" />
                    <DateField showTime={true} label="更新时间" source="updateAt" />
                </Datagrid>)}
        </List>
    );
}
