import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';

const getUserInfo = () => {
    const userInfo = localStorage.getItem('me');
    return JSON.parse(userInfo);
}

export default () => {
    const userInfo = getUserInfo()
    if(!userInfo){
        window.location = '/#login';
    }
    return (
        <Card>
            <CardHeader title={`hi! ${userInfo.fullName} 欢迎使用九公里算法平台`}/>
            <CardContent></CardContent>
        </Card>
    );
};
